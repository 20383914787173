<template>
  <ion-page>
  <br>
  
  <div style="flex-direction: row;">
    <div style="margin-top: 0px">
    
      <img 
        src="/assets/img/go_back.svg" 
        style="margin-left: 35px;position: absolute;z-index: 50" 
        @click="$router.go(-1)" 
      >
  
      <ion-title 
        style="text-align: center;"
      >
        Notas {{siniestro?.expediente}} 
        
        <template v-if="!getUser.role.id == 1">
          <ion-icon 
            v-if="message_selected == null"
            @click="openPopover" 
            style="float: right;" 
            :icon="bookmarks" 
            :ios="bookmarksOutline" 
            :md="bookmarks"
          ></ion-icon>
        </template>
        
        <template v-else>
            <ion-icon 
            v-if="message_selected == null"
            @click="openPopover" 
            style="float: right;" 
            :icon="bookmarks" 
            :ios="bookmarksOutline" 
            :md="bookmarks"
          ></ion-icon>
        </template>
        
        <ion-icon
          v-if="message_selected != null && getUser.role.id == 1"
          @click="eliminarMessageConfirmacion()" 
          style="float: right;" 
          :icon="trash" 
          :ios="trashOutline" 
          :md="trash"
        >
        </ion-icon>
        <template v-if="!message_selected_is_file">
          <ion-icon 
            v-if=" message_selected != null && getUser.role.id == 1"
            style="float: right;margin-right: 18px;"
            @click="edit_message" 
            :icon="create" 
            :ios="createOutline" 
            :md="create"
          ></ion-icon>
        </template>

        <ion-icon
          v-if="getUser.role.name == 'Despacho'"
          style="float: right;margin-right: 10px;" 
          @click="downloadZip"
          :icon="download" 
          :ios="downloadOutline" 
          :md="download"
          title="Descargar archivos adjuntos comprimidos"
        >
        </ion-icon>
      </ion-title>
    </div>
  </div>
  
  <ion-content class="ion-padding">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <!--height: 80%;-->
    <div  class="content" :style="messages_styles" @click="selectMessage">
     
      <div class="messages" id="chat" style="overflow:auto;" >
        
        <div v-for="(message) in messages.data" :key="message">   
          <ul >
            <template v-if="message?.is_file">
              <li 
                :class="{
                    'sent': getUser.id == message?.user_id,
                    'replies': getUser.id != message?.user_id,
                    'selected-message': message_selected == 'message-'+message.id
                  }" 
                 
                  :id="'message-'+message.id"
                >
                <ion-label :id="'label-'+message.id">
                  
                  <small :id="'small-'+message.id">
                    {{message?.user?.user_name}}
                    <template v-if="message?.level == 0">
                      {{' - Nota Privada'}} 
                    </template> 
                  </small>
                  <br>
                  
                  <button 
                    @click="downloadFile(message)"
                    style="background: #cfd1d4;color: #777777;" 
                  >
                    Ver archivo adjunto 
                    <ion-icon  
                      :icon="download" 
                      :ios="downloadOutline" 
                      :md="download"
                    >
                    </ion-icon>
                  </button>

                </ion-label>
                
                <br><br>
                
                <label 
                  :id="'date-'+message.id"
                >
                  {{moment(message.created_at, moment.ISO_8601).format('D/M/y h:mm a')}}
                </label>
              </li>
            </template>
            <template v-else>
              <li 
                :class="{
                  'sent': getUser.id == message?.user_id,
                  'replies': getUser.id != message?.user_id,
                  'selected-message': message_selected == 'message-'+message.id
                }" 
               
                :id="'message-'+message.id"
              >
                <small :id="'small-'+message.id"
                >
                  {{message?.user?.user_name}}
                  <template v-if="message?.level == 0">
                    {{' - Nota Privada'}} 
                  </template> 
                </small>
                
                <br>
                
                <ion-label 
                  v-html="message?.message?.replace(/(?:\r\n|\r|\n)/g,'<br>','i')"
                  :id="'label-'+message.id"
                >
                </ion-label>
                
                <br>
                <br>
                
                <label  
                  :id="'date-'+message.id"
                >
                  {{moment(message.created_at, moment.ISO_8601).format('D/M/y h:mm a')}}
                </label>
                
              </li>
              
            </template>
           
          </ul>
        </div> 
      </div>
    </div>
  </ion-content>
  
  <div :style="input_styles" class="message-input">
    
    <div> 
        <label class="full-width">
            Notificar por email
            <input type="checkbox" v-model="sendEmail" />
        </label>
    </div>
    <textarea 
      v-if="message_edit == null"
      v-model="message" 
      style="padding-top: 13px" 
      placeholder="Escribir un mensaje" 
      class="input-text"
    >
    </textarea>
    <textarea 
      v-if="message_edit != null"
      v-model="message_edit" 
      style="padding-top: 13px" 
      placeholder="Escribir un mensaje" 
      class="input-text"
    >
    </textarea>
    
    <i 
      class="icon" 
      style="margin-left: -45px;margin-top: -5px;"
    >
      <img  
        v-if="message_edit == null" 
        style="margin-top: -68px" 
        @click="setOpen(true)" 
        src="assets/img/upload_image.svg"
      >
    
    </i> 
  
    <img
      v-if="message_edit != null && message_selected != null" 
      src="assets/img/save.svg" 
      @click="send_edit_message()" 
      style="margin-left: 50px;margin-top: -72px;width: 30px;height: 30px;"
    >

    <img 
      v-else
      src="assets/img/send.png" 
      @click="send()" 
      style="margin-left: 30px;margin-top: -65px"
    >

    <ion-select 
      v-if="this.getUser.role.name != 'Cliente'"  
      class="input-text"  
      interface="action-sheet" 
      v-model="level" 
      style="color: #000;" 
    > 
      <ion-select-option 
        v-for="option in options" 
        :value="option.type" 
        :key="option"
      >
        {{option.label}}
      </ion-select-option>
    </ion-select>
  </div>
  
   <ion-modal
      :is-open="isOpenRef"
      :enterAnimation="enterAnimation"
      :leaveAnimation="leaveAnimation"  
      css-class="my-custom-class"
      @didDismiss="setOpen(false)"
      @ionModalWillDismiss="setOpen(false)"
    >
    <ModalUpload 
      @get="getPhoto($event)" 
      @close="setOpen(false)" 
    >
    </ModalUpload>
  </ion-modal>
</ion-page>
</template>

<script>

import { 
  defineComponent,
  ref 
} from 'vue';

import { 
  IonContent,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  createAnimation, 
  IonSelect,
  IonSelectOption,
  popoverController,
  alertController
} from '@ionic/vue';

import { 
  downloadOutline, 
  download,
  bookmarksOutline,
  bookmarks,
  chevronDownCircleOutline,
  trashOutline,
  trash,
  createOutline, 
  create, 
} from 'ionicons/icons';

import { 
  mapGetters 
} from 'vuex'

import moment  from 'moment'
import axios from 'axios'
import toast from '@/plugins/toast'
import Pusher from 'pusher-js'
import ModalUpload from '@/components/ModalUpload'
import PopoverSiniestro from './PopoverSiniestro'

export default defineComponent({
  components: { 
    IonContent, 
    IonRefresherContent,
    IonRefresher,
    IonModal, 
    ModalUpload,
    IonSelect,
    IonSelectOption
  },
  data(){
    return {
      BasePublic: axios.defaults.baseURL,
      input_styles : {
        'bottom' : '50px' 
      },
      messages_styles : {
        'height' : '60%', 
        'float' : 'left', 
        'width' : '100%', 
      },
        sendEmail: false,
      moment,
      message: null,
      message_edit :null,
      siniestro: null,
      siniestro_id: null,
      chat_id: null,
      messages: [],
      message_selected: null,
      message_selected_is_file : false,
      level_auth : null,
      despacho_tipo_notas : [
        {
          type : 0,
          label : 'Privadas'
        },
        {
          type : 1,
          label : 'Despacho y colaborador'
        },
        {
          type : 2,
          label : 'Todos'
        }
      ],
      colaborador_tipo_notas : [
        {
          type : 1,
          label : 'Despacho'
        },
        {
          type : 2,
          label : 'Todos'
        },
      ], 
      options:[],
      loading : false,
      page : 1
    }
  },
  setup(){
    const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
  
    return {  
      isOpenRef,
      setOpen,
      downloadOutline,
      download,
      bookmarksOutline,
      bookmarks,
      chevronDownCircleOutline, 
      trashOutline,
      trash,
      createOutline, 
      create,
    }
  },
  created(){
    
    this.level_auth = this.getUser.role.level

    if (this.getUser.role.name == 'Colaborador'){
      this.options = this.colaborador_tipo_notas
    }else{
       this.options = this.despacho_tipo_notas
    }

    if (this.getUser.role.name == 'Cliente') {
      this.input_styles['bottom'] = '30px'
      this.messages_styles['height'] = '80%'
    }

    this.level = this.options[0].type
  },
  mounted(){
  
    var self = this

    document.querySelector('#chat').addEventListener('scroll', function(e) {
        if (e.target.scrollTop > 1 && e.target.scrollTop < 25) {
          console.log(e.target.scrollTop)
          //self.getMessagesNextPage()
        }
    })

    this.chat_id = this.$route.params.chat_id
    var siniestro_id = this.$route.query.siniestro_id
    this.siniestro_id = this.$route.query.siniestro_id
    this.setStatusNotifier()
    this.getSiniestro(siniestro_id)
    this.getMessages()
    this.read_at()


    var pusher = new Pusher('1c14caa5f8d38f7808c0', {
      cluster: 'ap3'
    });

    var channel = pusher.subscribe('chat');

    channel.bind('messages-'+self.chat_id, function(data) {
      if (data.user_id != self.getUser.id && data.level != 0) {
        console.log("sono aqui")
        const music = new Audio('/assets/sounds/Plink.mp3');
        music.play();
        self.getMessages()
      }
    });

  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  methods:{
    scrollTop(){
      console.log("aqui")
    },
    selectMessage(ev){
      if (this.getUser.role.id != 1) {
          return
      }

      let id = ev.target.id.split("-")[1]
      
      if (id === undefined ) {
        this.message_selected = null;
        this.message_edit = null
        this.message_selected_is_file = false;
        return 
      }

      this.message_selected = 'message-'+id
      
      let selected = this.messages.data.find(message => message.id == id)
     
      this.message_selected_is_file = selected.is_file
    },
    getMessages(){
      
      axios.get('/api/chats/'+this.chat_id)
      .then(res => {
        this.messages = res.data.data;
        setTimeout(function () {
             document.getElementById('chat').scrollTop = document.getElementById('chat').scrollHeight;
          },80)
      })
      .catch(error => {
        console.log(error);
      });
    },
    edit_message(){
      let id = this.message_selected.split("-")[1]
      
      let selected = this.messages.data.find(message => message.id == id)

      this.message_edit = selected.message
    },
    async send_edit_message(){
      var loading = await toast.showLoading()

      await loading.present();

      let id = this.message_selected.split("-")[1]
      
      axios.post('/api/chats/'+id,{
        message : this.message_edit,
        level : this.level,
        sendEmail : this.sendEmail
      })
      .then(res => {
        this.message_edit = null;
         toast.openToast("Nota modificado exitosamente","error",2000)
        loading.dismiss()
        this.getMessages();
        console.log(res)
      })
      .catch(error => {
        loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
        this.sendEmail = false
    },
    setStatusNotifier(){
      axios.post('/api/siniestros/set/status/notifier',{
        siniestro_id : this.siniestro_id,
        user_id : this.getUser.id
      })
      .then(res => {
        res
        console.log("OK")
      })
      .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    refresh(event){
      console.log('Begin async operation');
      this.getMessagesNextPage().then(() => event.target.complete()).catch(() => event.target.complete())
    },
    getMessagesNextPage(){

      if (this.loading) {
        return;
      }

      this.loading = true
      this.page++ 
        
      axios.get('/api/chats/'+this.chat_id+'?page='+this.page)
      .then(res => {
        //this.messages = res.data.data;
        var messages_ = res.data.data
        messages_.push(this.messages)
        this.messages = messages_;
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    getSiniestro(siniestro_id){
      
      axios.get('/api/siniestros/'+siniestro_id)
      .then(res => {
        this.siniestro = res.data.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    async eliminarMessageConfirmacion() {

        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: '¡Confirmación!',
          message: '¿Realmente desea eliminar esta nota?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel:', blah);
              },
            },
            {
              text: 'Eliminar',
              handler: async () => {
                var loading = await toast.showLoading()
                let id = this.message_selected.split("-")[1]
                await loading.present();
                
                console.log('Confirm Okay');
                
                axios.delete('/api/chats/'+id+'/message')
                .then(res =>{
                  toast.openToast("Nota eliminado exitosamente","error",2000)
                  this.message_edit = null;
                  loading.dismiss()
                  this.getMessages();
                  console.log(res)
                }).catch(err =>{
                  loading.dismiss()
                  console.log(err)
                })
              },
            },
          ],
        });
        return alert.present();
      },
    async openPopover(ev) {
      
      const popover = await popoverController.create({
        component: PopoverSiniestro,
        cssClass: 'class',
        event: ev,
        translucent: false,
        showBackdrop : false,
        componentProps : {siniestro : this.siniestro}
      });
      
      await popover.present();

      const { role } = await popover.onDidDismiss();
      
      console.log('onDidDismiss resolved with role', role);
    },
    read_at(){
      axios.put('/api/chats/'+this.chat_id+'/read_at')
      .catch(error => {
        console.log(error)
      })
    },
    async send(){
      var loading = await toast.showLoading()

      await loading.present();

      let data = {
        message : this.message,
        chat_id : this.chat_id,
        level : this.getUser.role.name == 'Cliente' ? 2 : this.level,
        is_file: false,
        sendEmail: this.sendEmail
       }

      axios.post('/api/chats',data)
       .then(res => {
         loading.dismiss()
        console.log(res)
          this.message = null;
          //this.siniestros = res.data.data;
          this.getMessages()
       })
       .catch(error => {
          loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
        this.sendEmail = false
    },
    async getPhoto($event){
      
      this.setOpen(false)
      
      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData;

      formData.append('chat_id',this.chat_id);
      formData.append('is_file',true);
      formData.append('level', this.getUser.role.name == 'Cliente' ? 2 : this.level);
      
      for (var i = 0; i < $event.files.length; i++) {
         formData.append('files-'+i,$event.files[i]);
      }

      axios.post('/api/chats',formData)
       .then(res => {
          loading.dismiss()
        console.log(res)
          this.message = null;
          //this.siniestros = res.data.data;
          this.getMessages()
       })
       .catch(error => {
          loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    async downloadFile(message){
      var loading = await toast.showLoading()

      await loading.present();

      axios({
        url: '/api/chats/download/file',
        method: 'POST',
        responseType: 'blob', // important
        data: { url_file : message.message }
      }).then((response) => {
        loading.dismiss()
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        
        const f = message.message.split("/")
        const filename = f[f.length-1];
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
          loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    async downloadZip(){

      var loading = await toast.showLoading()

      await loading.present();

      //window.open(axios.defaults.baseURL+"/api/chats/"+this.chat_id+"/download/zip",'_blank');
      
      axios({
        url: "/api/chats/"+this.chat_id+"/download/zip",
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        loading.dismiss()
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'archivos_adjunto_'+this.siniestro?.expediente+'.zip');
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
          loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });

    },
    enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }   
});

</script>


<style type="text/css">


.input-text{
  margin-bottom: 15px;padding-left: 17px;background: #F5F5F5;border: 1px solid rgba(183, 183, 183, 0.3);box-sizing: border-box;border-radius: 24px;width :90%;height : 48px;padding-right: 53px;
}
.replies{
  float: left;
}
.sent{
  float: right;
}
.li-img{
  height: 168px !important;
  width: 100px !important;
 border-radius: 10px 10px 10px 10px !important;
}

  .arrow-back-margin{
    margin-top: 17px !important;
  }
  

  .absolute{
    position: absolute;
    width: 50px;
    height: 50px;
    margin-top: -40px;
    margin-left: 6px;
  }

  .not-absolute{
   width: 50px;height: 50px;
  }
  
  ion-badge{
    border-radius: 15px;
    position: absolute;
    margin-left: 5px;
    width: 24px;
    height: 24px;
    padding-top: 4.5px;
  }
  
  .active-tabs{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2280ED;
  }
  
  .tabs{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #6D6D6D; 
  }
  
  .active-div{
    display: block;
  }

  .no-active-div{
    display: none;
  }

</style>

<style scoped>

.selected-message{
  background: #a6c0e7 !important;
}

.margin-time{
  margin-top: 0px !important;
}

.replies-margin{
  margin-top: 70px;
}

.time-left{
  font-family: Segoe UI;font-style: normal;font-weight: normal;font-size: 13px;line-height: 17px;color: #000000;
  margin-left: 10px;
}

.time-right{
  font-family: Segoe UI;font-style: normal;font-weight: normal;font-size: 13px;line-height: 17px;color: #000000;float: right;
    margin-right: 5px;
    margin-top: -20px;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /*background: #E2E2E2;*/
  font-family: Segoe UI;
  font-size: 1em;
  letter-spacing: 0.1px;
  color: #32465a;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
}

#app {
  width: 95%;
  min-width: 360px;
  max-width: 1000px;
  height: 92vh;
  min-height: 300px;
  max-height: 720px;
  background: #E2E2E2;
}
@media screen and (max-width: 360px) {
  #app {
    width: 100%;
    height: 100vh;
  }
}
#app #sidepanel {
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 40%;
  height: 100%;
  background: #2c3e50;
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #app #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}
#app #sidepanel #profile {
  width: 80%;
  margin: 25px auto;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #32465a;
  }
}
#app #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#app #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#app #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#app #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap {
    height: 55px;
  }
}
#app #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}
#app #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#app #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#app #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#app #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#app #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap p {
    display: none;
  }
}
#app #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #32BAB0;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#app #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #32BAB0;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#app #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#app #sidepanel #profile .wrap #status-options:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #32BAB0;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#app #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#app #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#app #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}
#app #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#app #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#app #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#app #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71;
}
#app #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
  border: 1px solid #2ecc71;
}
#app #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f;
}
#app #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
  border: 1px solid #f1c40f;
}
#app #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
  background: #e74c3c;
}
#app #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
  border: 1px solid #e74c3c;
}
#app #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
  background: #95a5a6;
}
#app #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
  border: 1px solid #95a5a6;
}
#app #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#app #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#app #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}
#app #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #32BAB0;
}
#app #sidepanel #search {
  border-top: 1px solid #32465a;
  border-bottom: 1px solid #32465a;
  font-weight: 300;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #search {
    display: none;
  }
}
#app #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}
#app #sidepanel #search input {
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  padding: 10px 0 10px 46px;
  width: calc(100% - 25px);
  border: none;
  background: #32465a;
  color: #f5f5f5;
}
#app #sidepanel #search input:focus {
  outline: none;
  background: #32BAB0;
}
#app #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}
#app #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}
#app #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}
#app #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}
#app #sidepanel #contacts {
  height: calc(100% - 177px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  #app #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}
#app #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#app #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
}
#app #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}
#app #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}
#app #sidepanel #contacts ul li.contact:hover {
  background: #32465a;
}
#app #sidepanel #contacts ul li.contact.active {
  background: #32465a;
  border-right: 5px solid #32BAB0;
}
#app #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important;
}
#app #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#app #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: -2px 0 0 -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6;
}
#app #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}
#app #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}
#app #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}
#app #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}
#app #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}
#app #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
}
#app #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
#app #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: .5;
}
#app #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#app #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}
#app #sidepanel #bottom-bar button:focus {
  outline: none;
}
#app #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}
#app #sidepanel #bottom-bar button:hover {
  background: #32BAB0;
}
#app #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: 735px) {
  #app #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #app #sidepanel #bottom-bar button span {
    display: none;
  }
}
#app .content {
  float: right;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #app .content {
    width: calc(100% - 58px);
    min-width: 300px !important;
  }
}
@media screen and (min-width: 900px) {
  #app .content {
    width: calc(100% - 340px);
  }
}
#app .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#app .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}
#app .content .contact-profile p {
  float: left;
}
#app .content .contact-profile .social-media {
  float: right;
}
#app .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#app .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#app .content .contact-profile .social-media i:hover {
  color: #32BAB0;
}
#app .content .messages {
  height: auto;
  min-height: calc(90%);
  max-height: calc(100% - 93px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #app .content .messages {
    max-height: calc(100% - 105px);
  }
}

#app .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;

}
#app .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

#app .content .messages ul li {
  display: inline-block;
  clear: both;
  margin: 15px 15px 12px -20px;
  font-size: 0.9em;
  background: #cfd1d4;
  width: auto;
  max-width: 260px;
  padding: 5px;
  border-radius: 6px 6px 6px 0px;
}
#app .content .messages ul li:nth-last-child(1) {

}
#app .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#app .content .messages ul li.sent p {
  background: #FFFFFF;
  color: #f5f5f5;
   margin-left: -5px;
     width: auto;
  height: auto;
   text-align: revert;
   color: #000;
   border-radius: 10px 10px 10px 0px;
   margin-bottom: -10px;
   margin-top: -2px;
       padding-top: 6px;

}
#app .content .messages ul li.replies img {
  float: right;
  margin: 0px 0 0 8px;
}
#app .content .messages ul li.replies p {
  background: #E9EBEB;
  float: right;
  width: auto;
  height: auto;
text-align: revert;
color: #000;
border-radius: 10px 10px 0px 10px;
    margin-top: -25px;
    padding-top: 6px;
     margin-right: -5px;
}
#app .content .messages ul li img {
  width: 30px;
  height: 30px;
  width: 22px;
  border-radius: 50%;
  float: left;
}
#app .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 269px;
  line-height: 130%;
}
@media screen and (min-width: 1000px) {
  #app .content .messages ul li p {
    max-width: 300px;
  }
}
 .message-input {
  position: absolute;
  bottom: 50px;
  width: 100%;
  z-index: 99;
  margin-left: 3%;
  flex-direction: row;
}

.ios-message-input {
  position: absolute;
  bottom: 34px !important;
  width: 100%;
  z-index: 99;
  margin-left: 3%;
}
 .message-input .wrap {
  position: relative;
}
 .message-input .wrap input {
  font-family: "Montserrat";
  float: left;
  border: none;
  width: calc(100% - 90px);
  padding: 11px 32px 10px 8px;
  font-size: 0.8em;
  color: #000000;
  padding-left: 6%;
  background: #E9EBEB;
border-radius: 30px;
width: 284px;
height: 53px;
margin-left: 12px

}
@media screen and (max-width: 1000px) {

  .message-input .wrap input {
        margin-top: 3%;
      padding-left: 6%;
      margin-left: 2px;
  background: #E9EBEB;
border-radius: 30px;
margin-top: 1%;
    padding-left: 5%;
    width: 78%;
  }
   .message-input {
  position: absolute;
  
  bottom: 5px;
  width: 100%;
  z-index: 99;
}
}
@media screen and (min-width: 1000px) {
  .message-input .wrap input {
    
    padding-left: 3%;
    margin-left: 2%;
    background: #E9EBEB;
    border-radius: 30px;
      bottom: 10%;
        width: 78%;
  }
}
 .message-input .wrap input:focus {
  outline: none;
}
.message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #32BAB0;
  opacity: .5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
 .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}

 .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #fff;
  color: #f5f5f5;
}
@media screen and (max-width: 735px) {
 .message-input .wrap button {
    padding: 16px 0;
  }
}

.message-input .wrap button:focus {
  outline: none;
}

.img-right{
      height: 168px;
    width: auto;
    float: right;
      margin-right: 3px;
      border-radius: 10px 10px 10px 10px;
}

.img-left{
  height: 168px;
  width: auto;
  float: left;
  margin-left: 10px;
  border-radius: 10px 10px 10px 10px;
}


 @media (min-width: 600px){
   .boton-send{
  margin-left: -180px !important;
  margin-top: -5px  !important;
}
  }

  .class-modal .modal-wrapper{
  --width: 650px;
  --height: 500px;
}

</style>




